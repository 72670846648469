<template>
  <div class="left-menu-page">
    <div class="menu-box">
      <Menu
        accordion
        width="auto"
        @on-select="goThere"
        :active-name="currentName"
        :open-names="openedSubmenu"
        ref="menu"
        class="menu-bg"
      >
        <!-- <MenuItem name="home">
          <span class="iconfont icon-zhuye1" style="margin-right: 10px"></span>
          <span class="menu-name">首页</span>
        </MenuItem> -->
        <template v-for="el in menuList.children">
          <MenuItem
            v-if="(!el.children || el.children.length < 1 || el.levels == 2) && el.isopen == 1"
            :key="el.id"
            :name="el.url"
          >
            <span
              v-if="el.icon"
              :class="['iconfont', 'icon-' + el.icon]"
              style="margin-right: 10px"
            ></span>
            <span class="menu-name">{{ el.name }}</span>
          </MenuItem>
          <Submenu
            v-if="el.children && el.children.length > 0 && el.levels == 1"
            :key="el.id"
            :name="el.name"
          >
            <template slot="title">
              <span
                v-if="el.icon"
                :class="['iconfont', 'icon-' + el.icon]"
                style="margin-right: 10px"
              ></span>
              <span class="menu-name">{{ el.name }}</span>
            </template>
            <template v-for="it in el.children">
              <MenuItem
                :key="it.id"
                v-if="(!it.children || it.children.length < 1 || it.levels == 2) && it.isopen == 1"
                :name="it.url"
              >
                <span style="margin-right: 5px">●</span>
                <span class="menu-name">{{ it.name }}</span>
              </MenuItem>
            </template>
          </Submenu>
        </template>
      </Menu>
    </div>
    <!-- <p class="copy-right">技术支持：重庆芯达智胜科技有限公司</p> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "",
  data() {
    let matched = this.$route.matched;
    return {
      currentName: matched.length > 2 ? matched[2]?.name : matched[1]?.name,
      openedSubmenu: matched.length > 2 ? [matched[1]?.name] : [],
    };
  },
  computed: {
    ...mapState({
      menuList: (state) => state.permission.menuList
        // state.permission.currentRole
        //   ? state.permission.currentRole.treeMenu
        //   : [],
    }),
  },
  methods: {
    getOpendMenu(url, list) {
      if (!list) list = this.menuList?.children??[];
      for (let i = 0; i < list.length; i++) {
        let router = list[i].url;
        if (router == url) return true;
        if (list[i].children && list[i].children.length > 0) {
          let subResult = this.getOpendMenu(url, list[i].children);
          if (subResult.length > 0 || subResult === true) {
            let result =
              subResult === true
                ? [router || list[i].name]
                : [router || list[i].name, ...subResult];
            return result;
          }
        }
      }
      return [];
    },
    getCurrentFromLocation() {
      let matched = this.$route.matched;
      let length = matched.length;
      this.currentName = matched[length - 1]?.name;
      let openedSubmenu = this.getOpendMenu(this.currentName);
      this.openedSubmenu = openedSubmenu === true ? [] : openedSubmenu;
      this.$nextTick(() => {
        this.$refs.menu.updateOpened();
        this.$refs.menu.updateActiveName();
      });
    },

    goThere(router) {
      this.$router.push(
        { name: router },
        () => {},
        () => {}
      );
    },
  },
  mounted() {
    this.getCurrentFromLocation();
  },

  watch: {
    $route() {
      this.getCurrentFromLocation();
    },
    menuList() {
      this.getCurrentFromLocation();
    }
  },
};
</script>

<style lang="less" scoped>
.left-menu-page {
  width: 100%;
  height: 100%;
  background: #17223c;
  position: relative;
  .sys-info {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #fff;
    img {
      width: 60px;
      height: 40px;
    }
    p {
      font-size: 18px;
      color: #fff;
      padding-left: 10px;
    }
  }
  .menu-box {
    height: calc(~"100% - 40px");
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    .menu-bg {
      background: #17223c;
      color: #fff;
      border-left: none;
      /deep/ .ivu-menu {
        color: #fff;
      }
    }
  }
  .copy-right {
    font-size: 12px;
    color: #d3d3d3;
    padding: 15px 0;
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top: 2px solid #d3d3d3;
    text-align: center;
  }
}
/deep/
  .ivu-menu-light.ivu-menu-vertical
  .ivu-menu-item-active:not(.ivu-menu-submenu) {
  background: rgba(216, 224, 243, 0.16);
}
</style>
<style>
.ivu-menu-item{
  white-space: nowrap;
}
.green-theme .ivu-menu-vertical .ivu-menu-item:hover,
.green-theme .ivu-menu-vertical .ivu-menu-submenu-title:hover {
  color: #06baa1;
}
.green-theme
  .ivu-menu-light.ivu-menu-vertical
  .ivu-menu-item-active:not(.ivu-menu-submenu) {
  color: #06baa1;
}
.green-theme
  .ivu-menu-light.ivu-menu-vertical
  .ivu-menu-item-active:not(.ivu-menu-submenu):after {
  background: #06baa1;
}
.blue-theme .ivu-menu-vertical .ivu-menu-item:hover,
.blue-theme .ivu-menu-vertical .ivu-menu-submenu-title:hover {
  color: #2d8cf0;
}
.blue-theme
  .ivu-menu-light.ivu-menu-vertical
  .ivu-menu-item-active:not(.ivu-menu-submenu) {
  color: #2d8cf0;
}
.blue-theme
  .ivu-menu-light.ivu-menu-vertical
  .ivu-menu-item-active:not(.ivu-menu-submenu):after {
  background: #2d8cf0;
}
.purple-theme .ivu-menu-vertical .ivu-menu-item:hover,
.purple-theme .ivu-menu-vertical .ivu-menu-submenu-title:hover {
  color: #8d0077;
}
.purple-theme
  .ivu-menu-light.ivu-menu-vertical
  .ivu-menu-item-active:not(.ivu-menu-submenu) {
  color: #8d0077;
}
.purple-theme
  .ivu-menu-light.ivu-menu-vertical
  .ivu-menu-item-active:not(.ivu-menu-submenu):after {
  background: #8d0077;
}
</style>