<template>
  <div class="main-container-page">
    <div class="header">
      <header-bar v-once></header-bar>
    </div>
    <div class="main-page">
      <div class="left-pane">
        <left-menu></left-menu>
      </div>
      <div class="right-pane">
        <div class="content">
          <div class="inner-content" ref="innerContent">
            <router-view v-if="isRouterAlive"></router-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import leftMenu from "@/components/leftMenu";
import headerBar from "@/components/headerBar";
export default {
  name: "",
  components: {
    leftMenu,
    headerBar,
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  computed: {
    currentPath() {
      return this.$route.fullPath;
    },
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler(n) {
        if (
          n.name == "farmHome" ||
          n.name == "transportStatisticalAnalysis" ||
          n.name == "SAMCStatisticalAnalysis" ||
          n.name == "plantStatisticalAnalysis" ||
          n.name == "carTrack" ||
          n.name == "fwsenor" ||
          n.name == "driversStatus"
        ) {
          this.$nextTick(() => {
            this.$refs.innerContent.style.background = "#f6f6f6";
            this.$refs.innerContent.style.boxShadow = "none";
            this.$refs.innerContent.style.borderRadius = "0px";
          });
        } else {
          this.$nextTick(() => {
            this.$refs.innerContent.style.background = "#fff";
            this.$refs.innerContent.style.boxShadow = "0 0 4px 0 #ccc";
            this.$refs.innerContent.style.borderRadius = "4px";
          });
        }
      },
    },
  },
};
</script>

<style lang="less" scoped>
.main-container-page {
  width: 100%;
  height: 100%;
  //min-width: 1920px;
  min-height: 930px;
  @height: 71px;
  .header {
    width: 100%;
    height: @height;
  }
  .main-page {
    width: 100%;
    height: calc(100% - @height);
    display: flex;
    overflow: hidden;

    .left-pane {
      width: 230px;
      height: 100%;
      flex-shrink: 0;
      flex-grow: 0;
    }
    .right-pane {
      width: calc(~"100% - 230px");
      height: 100%;
      .content {
        width: 100%;
        // height: calc(100% - @height);
        height: 100%;
        padding: 16px;
        background: #f6f6f6;
        .inner-content {
          width: 100%;
          height: 100%;
          overflow: auto;
          background: #fff;
          border-radius: 4px;
          box-shadow: 0 0 4px 0 #ccc;
          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
    }
  }
}
</style>
